const details = {
    companyName: 'Shahid and Sons ',
    companyEmail: 'xyz@gmail.com',
    companyPhone1: '+44 (0) 793 266 4441',
    companyPhone2: '+44 (0) 795 130 3206',
    companyAddress: 'New York, NY 10012, US',
    companyFacebook: '',
    companyInstagram: '',
    companyTwitter: '',
    companyLinkedin: '',
}

export default details